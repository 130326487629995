<template>
  <div class="college">
    <bict-header/>
    <div class="bict-banner">
      <div class="bict-banner-bg">
        <img src="../assets/icon/icon_banner_college.png" alt="" class="image">
      </div>
      <div class="bict-banner-title">
        <h3 class="bict-banner-bg-text">{{ $t('CoinPlus Global Finance Institute') }}</h3>
      </div>
      <div class="bict-banner-title">
        <h3 class="bict-banner-bg-text2">{{ $t('Industry elite trading leaders 1v1 lectures, covering all financial sectors') }}</h3>
      </div>
    </div>
    <div class="bict-rank">
      <div class="bict-rank-title">
        <h3 class="bict-h3">{{ $t('Top trend ranking') }}</h3>
      </div>
      <div class="bict-table hidden-sm-and-down">
        <div class="bict-table-header">
          <div class="bict-table-tr">
            <div class="bict-table-th">
              <span class="text">{{ $t('Name of gold broker') }}</span>
            </div>
            <div class="bict-table-th">
              <span class="text">{{$t('Revenue last month')}}</span>
            </div>
            <div class="bict-table-th">
              <span class="text">{{ $t('Total revenue') }}</span>
            </div>
            <div class="bict-table-th">
              <span class="text">{{ $t('Accuracy') }}</span>
            </div>
          </div>
        </div>
        <div class="bict-table-body hidden-sm-and-down" v-loading="marketLoad">
          <div class="bict-table-tr" v-for="(item,index) in vList" :key="index">
            <div class="bict-table-td">
              <div class="bict-table-icon">
                <img :src="item.headUrl" alt="" class="icon">
              </div>
              <div class="bict-table-title">
                <p class="host">{{ item.userName }}</p>
                <p class="sub">{{ $t('Settled')}} {{ item.days }} {{$t('day')}}</p>
              </div>
            </div>
            <div class="bict-table-td">
              <div class="bict-table-title">
                <p class="text">{{ item.monthProfit }}</p>
              </div>
            </div>
            <div class="bict-table-td">
              <p class="text">{{ item.totalProfit }}</p>
            </div>
            <div class="bict-table-td">
              <p class="text">{{ item.correctRate }}%</p>
            </div>
          </div>
        </div>
      </div>

      <div class="bict-hot-rank hidden-sm-and-up" v-loading="marketLoad">
        <div class="bict-hot-rank-item" v-for="(item,index) in vList" :key="index">
          <div class="bict-hot-rank-item-header">
            <div class="bict-hot-rank-item-name">{{item.userName}}</div>
            <div class="bict-hot-rank-item-join-duration">{{ $t('Settled')}} {{ item.days }} {{$t('day')}}</div>
          </div>
          <div class="bict-hot-rank-item-body">
            <div class="bict-hot-rank-item-property">
              <div class="bict-hot-rank-item-property-value">{{ item.monthProfit }}</div>
              <div class="bict-hot-rank-item-property-name">{{$t('Revenue last month')}}</div>
            </div>
            <div class="bict-hot-rank-item-property">
              <div class="bict-hot-rank-item-property-value">{{ item.totalProfit }}</div>
              <div class="bict-hot-rank-item-property-name">{{ $t('Total revenue') }}</div>
            </div>
            <div class="bict-hot-rank-item-property">
              <div class="bict-hot-rank-item-property-value">{{ item.correctRate }}</div>
              <div class="bict-hot-rank-item-property-name">{{ $t('Accuracy') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bict-agent">
      <div class="bict-agent-bg">
        <img src="../assets/icon/icon_college_image.png" alt="" class="image">
      </div>
      <div class="bict-agent-box">
        <div class="bict-agent-title">
          <h3 class="bict-h3">{{ $t('Secret collection of gold brokerage') }}</h3>
        </div>
        <div class="bict-agent-date">
          <p class="text">【2021.12.01-2022.01.31】</p>
        </div>
        <div class="bict-agent-note">
          <p class="text">{{ $t('Limited time free subscription') }}</p>
        </div>
      </div>
    </div>
    <bict-bottom/>
  </div>
</template>

<script>
import BictHeader from "../components/BictHeader";
import BictBottom from "../components/BictBottom";
import { ProcoinHomeCropymeNoSign } from "../api";

export default {
  name: "index",
  components: {
    BictHeader,
    BictBottom
  },
  data () {
    return {
      vList: [],
      marketLoad: true
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.marketLoad = true
      const params = {
        lang: "tw",
      };
      ProcoinHomeCropymeNoSign(params).then(res => {
        if (res.code === "200") {
          this.vList = res.data.scoreRank;
          this.marketLoad = false
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .college {
    .bict-rank {
      width: 1080px;
    }

    .bict-guide {
      width: 1080px;
    }

    .bict-course {
      width: 1080px;
    }

    .bict-agent {
      width: 1200px;
    }
  }
}

@media screen and (max-width: 768px) {
  .college {
    .bict-rank {
      margin-top: 20px !important;
    }

    .bict-agent {
      margin-top: 20px !important;
    }
  }
}

.bict-hot-rank {
  margin-top: 20px;

  .bict-hot-rank-item {
    border: 1px solid #eeee;
    border-radius: 10px;
    margin-bottom: 20px;

    .bict-hot-rank-item-header {
      display: flex;
      align-items: center;
      padding: 10px;

      .bict-hot-rank-item-name {
        font-weight: bold;
        flex: 1;
        font-size: 12px;
      }
      .bict-hot-rank-item-join-duration {
        color: #999999;
        font-size: 12px;
      }
    }
    .bict-hot-rank-item-body {
      display: flex;
      align-items: center;
      padding: 10px;

      .bict-hot-rank-item-property {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .bict-hot-rank-item-property-value {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .bict-hot-rank-item-property-name {
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }
}
.college {
  background: #ffffff;

  .bict-banner {
    width: 100%;
    margin-top: 50px;
    position: relative;

    .image {
      width: 100%;
    }
    .bict-banner-title{
        .bict-banner-bg-text {
          margin-top: -200px;
          margin-right: 450px;
          text-align: center;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
         }
         .bict-banner-bg-text2 {
           margin-top: 13px;
           margin-right: 450px;
           text-align: center;
           font-size: 12px;
           font-family: PingFang SC;
           font-weight: bold;
           color: #ffffff;
         }
    }
  }

  .bict-rank {
    margin: 280px auto auto;
    overflow: hidden;
    padding: 0 18px;

    .bict-rank-title {
      .bict-h3 {
        text-align: center;
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }

    }

    .bict-table {
      margin-top: 60px;
      width: 100%;

      .bict-table-header {
        width: 100%;

        .bict-table-tr {
          display: flex;

          .bict-table-th {
            flex: 1;
            padding: 10px 0;
            text-align: center;

            .text {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #1572F4;
            }

            &:first-child {
              justify-content: left;
              width: 350px;
              flex: none;

            }
          }
        }
      }

      .bict-table-body {
        min-height: 300px;
        .bict-table-tr {
          display: flex;
          margin-top: 7px;
          height: 70px;

          .bict-table-td {
            flex: 1;
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;

            .text {
              margin: 0;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #333333;
              text-align: left;
            }

            .bict-table-icon {
              height: 100%;
              display: flex;
              align-items: center;
              margin-right: 5px;

              .icon {
                width: 40px;
                height: 40px;
              }
            }

            .bict-table-title {

              .host {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
              }

              .sub {
                font-size: 12px;
                font-family: FZHei-B01T;
                font-weight: 400;
                color: #7E8EA1;
              }
            }

            &:first-child {
              justify-content: left;
              width: 350px;
              flex: none;

              .bict-table-icon {
                margin-left: 30px;
              }
            }
          }
        }
      }

    }
  }

  .bict-guide {
    margin: 100px auto auto;
    overflow: hidden;
    padding: 0 18px;

    .bict-guide-head {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
      border-bottom: 1px solid #EEF0F2;
      align-items: flex-end;

      .bict-guide-left {
        .bict-h3 {
          display: inline-block;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #1C242E;
        }

        .bict-sub {
          margin-left: 28px;
          display: inline-block;
          font-size: 14px;
          font-family: FZHei-B01T;
          font-weight: 400;
          color: #999999;
        }
      }

      .bict-guide-more {
        .text {
          font-size: 14px;
          font-family: FZHei-B01T;
          font-weight: 400;
          color: #1572F4;
        }
      }
    }

    .bict-guide-box {
      margin-top: 20px;
      width: 100%;

      .bict-ul {
        overflow: hidden;
        width: 100%;

        .bict-li {
          list-style: none;
          width: 30%;
          float: left;
          margin: 0 1.66%;

          .bict-guide-image {
            .image {
              width: 100%;
            }
          }

          .bict-guide-p {
            margin-top: 10px;
            padding-left: 5px;

            .text {
              font-size: 17px;
              font-family: FZHei-B01T;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }
    }
  }

  .bict-course {
    margin: 60px auto auto;
    overflow: hidden;
    padding: 0 18px;

    .bict-course-head {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
      border-bottom: 1px solid #EEF0F2;
      align-items: flex-end;

      .bict-course-left {
        .bict-h3 {
          display: inline-block;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #1C242E;
        }

        .bict-sub {
          margin-left: 28px;
          display: inline-block;
          font-size: 14px;
          font-family: FZHei-B01T;
          font-weight: 400;
          color: #999999;
        }
      }

      .bict-course-more {
        .text {
          font-size: 14px;
          font-family: FZHei-B01T;
          font-weight: 400;
          color: #1572F4;
        }
      }
    }

    .bict-course-box {
      margin-top: 20px;
      width: 100%;

      .bict-ul {
        overflow: hidden;
        width: 100%;

        .bict-li {
          list-style: none;
          width: 30%;
          float: left;
          margin: 0 1.66%;

          .bict-course-image {
            .image {
              width: 100%;
            }
          }

          .bict-course-p {
            margin-top: 10px;
            padding-left: 5px;

            .text {
              font-size: 17px;
              font-family: FZHei-B01T;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }
    }
  }

  .bict-agent {
    margin: 80px auto auto;
    position: relative;

    .bict-agent-bg {
      .image {
        width: 100%;
        height: 300px;
      }
    }

    .bict-agent-box {
      top: 70px;
      left: 0;
      right: 0;
      text-align: center;
      position: absolute;

      .bict-agent-title {
        .bict-h3 {
          font-size: 34px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
        }
      }

      .bict-agent-date {
        margin-top: 20px;

        .text {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #BFD7FF;
        }
      }

      .bict-agent-note {
        margin-top: 20px;

        .text {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
